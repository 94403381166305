import type { DebouncedFunc } from 'lodash';
import { debounce as createDebounced } from 'lodash';
import { useEffect, useRef, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any) => void | undefined;
export type CancelableCallback = DebouncedFunc<Callback> | null;
export type Debounced = React.MutableRefObject<CancelableCallback>;

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): void {}

/**
 * This takes a function and a duration and debounces the value.
 * It will take care of cancelling any timeout when the component
 * is removed and ensures the callback always has access to latest state/props.
 * @param fn
 * @param duration
 */
export function useDebouncedCallback(fn?: Callback, duration?: number): CancelableCallback | Callback {
  const callbackFn = fn || noop;

  // Keep the callback in a ref to always have access to latest version
  const fnRef = useRef(callbackFn);

  // Update the ref whenever the callback changes
  useEffect(() => {
    fnRef.current = callbackFn;
  }, [callbackFn]);

  // Create a memoized version of the debounced function that always reads from fnRef
  const debouncedCallback = useCallback(
    (...args: any[]) => {
      if (duration) {
        return createDebounced((...innerArgs) => fnRef.current(...innerArgs), duration)(...args);
      }
      return fnRef.current(...args);
    },
    [duration],
  );

  // Store the debounced function in a ref to maintain the same reference
  const debouncedRef = useRef<CancelableCallback | Callback>(debouncedCallback);

  useEffect(() => {
    debouncedRef.current = debouncedCallback;

    // Cleanup function to cancel any pending debounced calls
    return () => {
      if (debouncedRef.current && 'cancel' in debouncedRef.current) {
        debouncedRef.current.cancel();
      }
    };
  }, [debouncedCallback]);

  return debouncedRef.current;
}
